import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild, Router } from '@angular/router'

import { TypeCanActivate } from '../../model/model-dto/auth.guard.type'
import { AuthService } from '../../services/auth.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(): TypeCanActivate {
    return this.isUserAuthorized()
  }

  canActivateChild(): TypeCanActivate {
    return this.isUserAuthorized()
  }

  isUserAuthorized(): TypeCanActivate {
    return this.authService.isLoggedIn() || this.router.parseUrl('/login')
  }
}
