import { Contact, ContactV2 } from '../model/model-dto/contact.type'
import { UserInfo } from '../model/model-dto/user-info.type'
import { TokenData } from '../shared/models/token-data.type'

export interface WebstorageHelper {
  clear: () => void
  get: <T>(key: string) => T | undefined
  getCachedContacts: () => ContactV2[] | undefined
  getConnectionData: () => TokenData | undefined
  getCurrentUserInfo: () => UserInfo
  getClientId: () => string | undefined
  getContactId: () => string | undefined
  getRequired: <T>(key: string) => T
  getRequiredConnectionData: () => TokenData
  set: (key: string, o?: unknown) => void
  setCachedContacts: (contacts?: Contact[] | ContactV2[]) => void
  setConnectionData: (connectionData?: TokenData) => void
  setCurrentUserInfo: (userInfo?: UserInfo) => void
  setClientId: (clientId: string) => void
  setContactId: (contactId: string) => void
  clearConnectionData: () => void
}

const webstorageHelper: WebstorageHelper = {
  clear: () => {
    localStorage.clear()
  },

  clearConnectionData: () => {
    localStorage.removeItem('connectionData')
  },

  get: <T>(key: string): T => {
    const rawValue = localStorage.getItem(key)
    if (rawValue === null) {
      return undefined
    }
    return JSON.parse(rawValue) as T
  },
  getCachedContacts: () => webstorageHelper.get<ContactV2[]>('myContacts'),
  getConnectionData: () => webstorageHelper.get<TokenData>('connectionData'),
  getCurrentUserInfo: () => webstorageHelper.getRequired<UserInfo>('currentUserInformations'),
  getClientId: () => webstorageHelper.getRequired<string>('clientId'),
  getContactId: () => webstorageHelper.getRequired<string>('contactId'),
  getRequired: <T>(key: string) => {
    const value = webstorageHelper.get<T>(key)
    if (value === undefined) {
      throw new Error(`value for key '${key}' not found in local storage`)
    }
    return value
  },
  getRequiredConnectionData: () => webstorageHelper.getRequired<TokenData>('connectionData'),
  set: (key: string, o?: unknown) => {
    if (o === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(o))
    }
  },
  setCachedContacts: (contacts) => {
    webstorageHelper.set('myContacts', contacts)
  },
  setConnectionData: (connectionData) => {
    webstorageHelper.set('connectionData', connectionData)
  },
  setCurrentUserInfo: (userInfo) => {
    webstorageHelper.set('currentUserInformations', userInfo)
  },
  setClientId: (clientId) => {
    webstorageHelper.set('clientId', clientId)
  },
  setContactId: (contactId) => {
    webstorageHelper.set('contactId', contactId)
  },
}

export default webstorageHelper
