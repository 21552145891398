import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule } from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import { NgModule } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { UtilsMaterialModule } from 'src/app/utils/material/utils-material.module'

import { AppComponent } from './app.component'
import { AuthService } from './services/auth.service'
import { FrenchDateAdapter } from './services/french-date-adapter.service'
import { AuthGuard } from './shared/auth/auth.guard'
import { SharedComponentsModule } from './shared/components/shared-components.module'
import { ApiLogoutInterceptorService } from './shared/interceptors/api-logout-interceptor.service'
import { UtilsSharedModule } from './shared/utils-shared.module'
import { ConfirmLoseFormChangesGuard } from './utils/guards/form-guard/can-deactivate/can-deactivate.guard'

registerLocaleData(localeFr, 'fr', localeFrExtra)

export const HttpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http) =>
  new TranslateHttpLoader(http)

registerLocaleData(localeFr)

const routes: Routes = [
  {
    loadChildren: () =>
      import('./pages/login-page/login-page.module').then((m) => m.LoginPageModule),
    path: 'login',
  },
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./shared/auth/authenticated-component/authenticated.module').then(
        (m) => m.AuthenticatedModule,
      ),
    path: '',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
]

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    UtilsSharedModule,
    UtilsMaterialModule,
    HttpClientJsonpModule,
    SharedComponentsModule,
  ],
  providers: [
    AuthService,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: ApiLogoutInterceptorService,
    },
    ConfirmLoseFormChangesGuard,
    { provide: DateAdapter, useClass: FrenchDateAdapter },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, panelClass: 'block-snackbar' },
    },
  ],
})
export class AppModule {}
