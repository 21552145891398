import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslateModule } from '@ngx-translate/core'
import {
  NgCapitalizePipeModule,
  NgReplacePipeModule,
  NgSplitPipeModule,
  NgUpperFirstPipeModule,
} from 'angular-pipes'
import { CalendarModule } from 'primeng/calendar'

import { UtilsSharedModule } from '../utils-shared.module'
import { AccountEntityRelationComponent } from './account-entity-relation/account-entity-relation.component'
import { AccountTypePictoComponent } from './account-type-picto/account-type-picto.component'
import { ClientCardComponent } from './client-card/client-card.component'
import { ClientNotificationButtonComponent } from './client-notification-button/client-notification-button.component'
import { DateNameFooterComponent } from './date-name-footer/date-name-footer.component'
import { ImgDialogComponent } from './img-dialog/img-dialog.component'
import { LoaderComponent } from './loader/loader.component'
import { NoteItemComponent } from './note-item/note-item.component'
import { ProfileHeaderComponent } from './profile-header/profile-header.component'
import { RgdpDialogComponent } from './rgpd/rgdp-dialog/rgdp-dialog.component'
import { RGPDComponent } from './rgpd/rgpd.component'
import { ButtonComponent } from './ui-components/button/button.component'
import { CarouselComponent } from './ui-components/carousel/carousel.component'
import { CapitalizeTitlePipe } from './ui-components/text/capitalize-title.pipe'
import { TextComponent } from './ui-components/text/text.component'

@NgModule({
  declarations: [
    AccountTypePictoComponent,
    LoaderComponent,
    NoteItemComponent,
    ImgDialogComponent,
    RGPDComponent,
    TextComponent,
    ButtonComponent,
    DateNameFooterComponent,
    CapitalizeTitlePipe,
    CarouselComponent,
    RgdpDialogComponent,
    ClientNotificationButtonComponent,
    ProfileHeaderComponent,
    AccountEntityRelationComponent,
    ClientCardComponent,
  ],
  exports: [
    AccountTypePictoComponent,
    LoaderComponent,
    NoteItemComponent,
    RGPDComponent,
    TextComponent,
    ButtonComponent,
    DateNameFooterComponent,
    CarouselComponent,
    ClientNotificationButtonComponent,
    ProfileHeaderComponent,
    AccountEntityRelationComponent,
    ClientCardComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    UtilsSharedModule,
    TranslateModule,
    NgSplitPipeModule,
    UtilsSharedModule,
    NgUpperFirstPipeModule,
    NgCapitalizePipeModule,
    NgSplitPipeModule,
    NgReplacePipeModule,
    CalendarModule,
  ],
})
export class SharedComponentsModule {}
