import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import webstorageHelper from '../../utils/webstorage-helper'

@Injectable()
export class ApiLogoutInterceptorService implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          webstorageHelper.clear()
          window.location.href = './'
        }
        if (0 === error.status && !error.url.includes('publications')) {
          webstorageHelper.clear()
          window.location.href = './'
        }
        if ((error.error as unknown as { errorCode: string }[])[0].errorCode === 'APEX_ERROR') {
          this.snackBar.open(
            this.translate.instant('APEX_ERROR'),
            this.translate.instant('common_close'),
            {
              duration: 10_000,
            },
          )
        }

        return throwError(() => error)
      }),
    )
  }
}
