import { CommonModule } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { SanitizePipe } from 'src/app/utils/sanitize-url-pipe'

import { ContactRelationCountPipe } from '../utils/contact-relation-count.pipe'
import { UtilsMaterialModule } from '../utils/material/utils-material.module'
import { TrimPipe } from '../utils/space-between-number.pipe'
import { TransformProductionPipe } from '../utils/transform-production.pipe'

// AoT requires an exported function for factories
export const HttpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http) =>
  new TranslateHttpLoader(http)

@NgModule({
  declarations: [TrimPipe, SanitizePipe, TransformProductionPipe, ContactRelationCountPipe],
  exports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    UtilsMaterialModule,
    TrimPipe,
    SanitizePipe,
    FormsModule,
    TransformProductionPipe,
    ContactRelationCountPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    UtilsMaterialModule,
  ],
})
export class UtilsSharedModule {}
