import { Injectable } from '@angular/core'

import webstorageHelper from '../utils/webstorage-helper'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn(): boolean {
    const connectionData = webstorageHelper.getConnectionData()
    return !!connectionData?.access_token
  }
}
