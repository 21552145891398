import { Injectable } from '@angular/core'
import { NativeDateAdapter } from '@angular/material/core'

@Injectable({
  providedIn: 'root',
})
export class FrenchDateAdapter extends NativeDateAdapter {
  parse(value: unknown): Date | null {
    if (typeof value === 'string' && value.includes('/')) {
      const str = value.split('/')
      if (
        str.length < 2 ||
        Number.isNaN(+str[0]) ||
        Number.isNaN(+str[1]) ||
        Number.isNaN(+str[2])
      ) {
        return null
      }
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12)
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value as string)
    return Number.isNaN(timestamp) ? null : new Date(timestamp)
  }
}
