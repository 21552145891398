import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

import { CanDeactivateComponent } from './component-can-deactivate'

@Injectable()
export class ConfirmLoseFormChangesGuard implements CanDeactivate<CanDeactivateComponent> {
  private readonly message: string

  constructor(private readonly translate: TranslateService) {
    this.message = this.translate.instant('common_data_loss')
  }

  canDeactivate(component: CanDeactivateComponent): boolean {
    if (!component.canDeactivate()) {
      return confirm(this.message)
    }
    return true
  }
}
