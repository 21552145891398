export const environment = {
  apiVersion: 'v50.0',
  clientId: '3MVG90J3nJBMnqrTmVU5.DBLz5C.fnFgCJPgkWem0tfaJmRNz7j3q4sVflg6Xj70xddn4HKVclHuLJttFDlrT',
  clientSecret: '52A629048F5666FB8566EF731BA2A122421CA409EC1851DA86963446A45A9680',
  enableApiMock: false,
  facebookApiUrl:
    'https://europe-west3-oeneo-transverse.cloudfunctions.net/bootstrap/publications?',
  loginUrl: 'https://oeneo--integ.sandbox.my.salesforce.com',
  production: false,
  develop: true,
  resetPasswordUrl: 'https://oeneo--integ.my.salesforce.com/secur/forgotpassword.jsp',
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
}
