import { Component } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'

import { AuthService } from './services/auth.service'
import webstorageHelper from './utils/webstorage-helper'

@Component({
  providers: [AuthService],
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private readonly translate: TranslateService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
    window.addEventListener('beforeunload', function () {
      webstorageHelper.setClientId('')
      webstorageHelper.setContactId('')
    })
    this.translate.setDefaultLang('fr')
    this.translate.use('fr')
    const icons = [
      { file: '/assets/images/logos/SEGUIN-MOREAU.svg', iconName: 'seguinmoreau' },
      { file: '/assets/images/logos/SEGUIN-MOREAU.svg', iconName: 'seguin moreau' },
      { file: '/assets/images/logos/diam-logo.svg', iconName: 'diam' },
      { file: '/assets/images/logos/vivelys-logo.svg', iconName: 'vivelys' },
      { file: '/assets/icons/icon.modify.svg', iconName: 'customModify' },
      { file: '/assets/icons/dashboard.svg', iconName: 'dashboard' },
      { file: `/assets/icons/logo-mobile-logo-mobile-actif@2x.svg`, iconName: 'logo' },
      { file: `/assets/icons/logo-oeneo-solo.svg`, iconName: 'icon-oeneo' },
      { file: '/assets/icons/linkedin.svg', iconName: 'linkedin' },
      { file: '/assets/icons/carte/navigation@2x.svg', iconName: 'trajectorydurationarrow' },
      { file: '/assets/icons/carte/map@2x.svg', iconName: 'customMap' },
      { file: 'assets/icons/avatar_blank.svg', iconName: 'profileIcon' },
      { file: '/assets/icons/icon carte.svg', iconName: 'icon_carte' },
      { file: '/assets/icons/icon mail.svg', iconName: 'icon_mail' },
      { file: '/assets/icons/icon tel.svg', iconName: 'icon_tel' },
      { file: '/assets/icons/globe.svg', iconName: 'globe' },
      { file: '/assets/icons/facebook.svg', iconName: 'facebook' },
      { file: '/assets/icons/linkedin.svg', iconName: 'linkedin' },
      { file: '/assets/icons/instagram.svg', iconName: 'instagram' },
      { file: '/assets/icons/add_to_favorites_checked.svg', iconName: 'favorites_checked' },
      { file: '/assets/icons/add_to_favorites_unchecked.svg', iconName: 'favorites_unchecked' },
      { file: '/assets/icons/account_balance.svg', iconName: 'account_balance' },
      { file: '/assets/icons/avatar_distribution.svg', iconName: 'avatar_distribution' },
      { file: '/assets/icons/avatar_distribution_wine.svg', iconName: 'avatar_distribution_wine' },
      { file: '/assets/icons/real_estate_agent.svg', iconName: 'real_estate_agent' },
      { file: '/assets/icons/account_balance_white.svg', iconName: 'account_balance_white' },
      { file: '/assets/icons/account_balance_wine.svg', iconName: 'account_balance_wine' },
      { file: '/assets/icons/loupe.svg', iconName: 'loupe' },
      { file: '/assets/icons/loupe.svg', iconName: 'loupe' },
      { file: 'assets/icons/like.svg', iconName: 'like' },
      { file: '/assets/icons/dislike.svg', iconName: 'dislike' },
      {
        file: '/assets/icons/avatar_distribution_white.svg',
        iconName: 'avatar_distribution_white',
      },
      { file: '/assets/icons/real_estate_agent.svg', iconName: 'real_estate_agent' },
      { file: '/assets/icons/real_estate_agent_white.svg', iconName: 'real_estate_agent_white' },
      { file: '/assets/icons/real_estate_agent_wine.svg', iconName: 'real_estate_agent_wine' },
    ]

    for (const { file, iconName } of icons) {
      this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(file))
    }
  }
}
